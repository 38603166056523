import React, { Fragment, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { connect } from "react-redux"
import { Box, ClickAwayListener } from "@material-ui/core"
import ChevRight from "../assets/icons/chevRight"
import HeaderDropDownComponent from "./headerComponents/headerDropDown"
import PriceRange from "./filter/priceRange"
import ChevDown from "../assets/icons/chevDown"
import SearchIcon from "../assets/icons/searchIcon"
import { setPropertyFiltersField } from "../actions/settingsActions"
import { listedByOptions } from "./propertyForm/steps/secondStep"
import { navigate } from "gatsby-link"
import { offerTypeOptions } from "./propertyForm/steps/firstStep"
import FormSelect, { CustomSelect } from "./propertyForm/formSelect"
import ReactSelect from "react-select"
import useMediaQuery from "./hooksComponents/useMediaQuery"

const Button = ({ children, active, onClick }) => (
  <button
    onClick={onClick}
    className={`__button font-bold px20 text-center ${
      active ? "primaryBg white" : "greyLightBg black"
    }`}
  >
    {children}
  </button>
)

const PopupButton = ({ children, onClick }) => (
  <button
    onClick={onClick}
    className="br4 greyLightBg black px16-responsive font-bold p-3 d-flex align-items-center justify-content-between flex-1"
  >
    {children}
    <ChevRight />
  </button>
)

const SearchBox = ({
  propertyTypes,
  propertyFilters,
  setPropertyFiltersField,
  lang,
}) => {
  const intl = useIntl()
  const [expanded, setExpanded] = useState()
  const [anchorEl, setAnchorEl] = useState()
  const [popupItems, setPopupItems] = useState([])

  const listByPopupItems = listedByOptions.map(({ value, label }) => ({
    page: label,
    action: () => {
      setPropertyFiltersField("relationship", value)
      setAnchorEl(null)
    },
  }))
  const cityOptions = [
    { title: "Homs", value: "Homs" },
    { title: "Deir ez-Zur", value: "Deir ez-Zur" },
    { title: "Ar Raqqah", value: "Ar Raqqah" },
    { title: "Hama", value: "Hama" },
    { title: "Damascus", value: "Damascus" },
    { title: "llion", value: "llion" },
    { title: "Tartus", value: "Tartus" },
    { title: "Al Bab", value: "Al Bab" },
    { title: "Duma", value: "Duma" },
    { title: "Manbij", value: "Manbij" },
    { title: "Dayr Hafir", value: "Dayr Hafir" },
    { title: "Ar Rastan", value: "Ar Rastan" },
    { title: "A'zaz", value: "A'zaz" },
    { title: "Al-Safirah", value: "Al-Safirah" },
    { title: "Darayya", value: "Darayya" },
    { title: "Al Mayadin", value: "Al Mayadin" },
    { title: "Salamiyah", value: "Salamiyah" },
    { title: "Al Tal", value: "Al Tal" },
    { title: "Al Bukamal", value: "Al Bukamal" },
    { title: "Al Tabqah", value: "Al Tabqah" },
    { title: "Jableh", value: "Jableh" },
    { title: "Badh Rajbana", value: "Badh Rajbana" },
    { title: "Khan Shaykhun", value: "Khan Shaykhun" },
    { title: "Al Hasakah", value: "Al Hasakah" },
    { title: "Tall Rifat", value: "Tall Rifat" },
    { title: "Tadif", value: "Tadif" },
    { title: "Nubl", value: "Nubl" },
    { title: "Al-Hawl", value: "Al-Hawl" },
  ]

  const goToPropertiesPage = () => navigate("/properties")

  const handleCollapse = () => {
    setExpanded(false)
  }

  const onOpenListByPopup = e => {
    setAnchorEl(e?.target)
    setPopupItems(listByPopupItems)
  }

  const setTypeValue = id => {
    setPropertyFiltersField("type", [id])
  }
  const setCityValue = city => {
    setPropertyFiltersField("city", city)
  }
  console.log("property_filt", propertyFilters)

  const onOpenPropertyTypePopup = e => {
    setAnchorEl(e?.target)
    setPopupItems(
      propertyTypes?.data?.map(pt => ({
        page: pt.title,
        action: () => {
          setTypeValue(pt.id)
          setAnchorEl(null)
        },
      }))
    )
  }
  const onOpenSelectCityPopup = e => {
    setAnchorEl(e?.target)
    setPopupItems(
      cityOptions?.map(pt => ({
        page: pt.title,
        action: () => {
          setCityValue(pt.value)
          setAnchorEl(null)
        },
      }))
    )
  }

  const handleOfferTypeClick = value => {
    setPropertyFiltersField("offerType", value)
    setExpanded(true)
  }

  const selectedPropType = propertyTypes?.data?.find?.(
    pt => pt.id === propertyFilters?.type?.[0]
  )

  const goToAdvancedFilterPage = () => navigate("/properties-filter")
  const isXs = useMediaQuery("(max-width: 991px)")
  const isSm = useMediaQuery("(max-width: 991px)")
  const isMd = useMediaQuery("(max-width: 280px)")
  const isLg = useMediaQuery("(max-width: 292px)")
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ display: "flex", justifyContent: "center" }}>
        <h1
          className={`letter-wide mb-5 text-uppercase font-800 text-center search-box__header ${
            expanded ? "px32" : "px86"
          }`}
          style={{
            lineHeight: "150%",
            fontFamily: "Inter",
            letterSpacing: "0.12em",
            fontStyle: "normal",
            color: "#253740",
            transitionDelay: expanded ? "0" : "0.2s",
          }}
        >
          <FormattedMessage defaultMessage="SIMSAR" />
          <div
            className="greyBg"
            style={{
              margin: "auto",
              width: "68px",
              height: 2,
              border: "none",
              marginTop: "5px",
            }}
          />
        </h1>
      </div>
      <ClickAwayListener onClickAway={handleCollapse}>
        <div className="search-box whiteBg br16 card-shadow01">
          <p className="px12 darkGrey letter-wide text-uppercase font-800 mb-4">
            <FormattedMessage defaultMessage="search properties to" />
          </p>
          <div className="w-100 d-flex br4 mb-4">
            {offerTypeOptions.map(({ value, label }, i) => (
              <Fragment key={value}>
                <Button
                  key={value}
                  active={propertyFilters?.offerType === value}
                  onClick={() => handleOfferTypeClick(value)}
                >
                  {label}
                </Button>
                {i < offerTypeOptions.length - 1 && (
                  <div
                    className="greyBg"
                    style={{
                      width: 1,
                      height: "auto",
                      border: "none",
                    }}
                  />
                )}
              </Fragment>
            ))}
          </div>

          <div
            style={{ overflow: "visible" }}
            className={`w-100 mt-4 expandable ${
              expanded ? "expandable--open" : ""
            }`}
          >
            <div className="w-100 d-flex space-x-20 mb-3">
              <PopupButton onClick={onOpenListByPopup}>
                {listedByOptions.find(
                  o => o.value === propertyFilters?.relationship
                )?.label || <FormattedMessage defaultMessage="List by" />}
              </PopupButton>
              <PopupButton onClick={onOpenPropertyTypePopup}>
                {propertyTypes?.data?.find(o => o.id == propertyFilters?.type)
                  ?.title || (
                  <FormattedMessage defaultMessage="Property Type" />
                )}
              </PopupButton>
            </div>
            <PriceRange
              customSizeClass="customSize"
              hasBackground={false}
              largerNums={false}
            />

            <div className="d-flex w-100 mb-4">
              <PopupButton onClick={goToAdvancedFilterPage}>
                <FormattedMessage defaultMessage="More advanced search" />
              </PopupButton>
            </div>

            <div className="w-100 selectCitySearchAndIcon">
              <button
                onClick={onOpenSelectCityPopup}
                className="d-flex align-items-center p-2 greyBg primary px16 font-bold no-wrap selectCitySearchBox">
                {propertyFilters?.city ? (
                  propertyFilters?.city
                ) : (
                  <FormattedMessage defaultMessage="Select City" />
                )}
                <ChevDown className="ms-2" />
              </button>

              <div className="searchInput searchStreetSearchBox">
                {cityOptions ? (
                  <ReactSelect
                    name="city"
                    placeholder={intl.formatMessage({
                      defaultMessage: "Search cities or streets",
                    })}
                    getOptionLabel={options => options["title"]}
                    getOptionValue={options => options["value"]}
                    options={[...cityOptions]}
                    onChange={city => setCityValue(city.value)}
                    value={
                      propertyFilters?.city
                        ? {
                            title: propertyFilters?.city,
                            value: propertyFilters?.city,
                          }
                        : null
                    }
                    className="w-100"
                    styles={{
                      control: (base, props) => ({
                        ...base,
                        minHeight: "3rem",
                        height: "49px",
                        borderRadius: "0px",
                        fontSize: "1.2rem",
                        border: "1px solid gray",
                        boxShadow: "none",
                        "&:hover": {
                          borderColor: "gray",
                        },
                      }),
                      option: (base, props) => ({
                        ...base,
                        backgroundColor: props.isSelected
                          ? "#3BBA9C"
                          : base.backgroundColor,
                      }),
                    }}
                  />
                ) : null}
                <button
                  onClick={goToPropertiesPage}
                  className="secondaryBg white br-e-4"
                >
                  <SearchIcon />
                </button>
              </div>
            </div>
          </div>

          <HeaderDropDownComponent
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            items={popupItems}
          />
        </div>
      </ClickAwayListener>
    </div>
  )
}

const mapStateToProps = ({
  data: { propertyTypes },
  settings: { lang, propertyFilters },
}) => ({
  propertyTypes,
  propertyFilters,
  lang,
})
export default connect(mapStateToProps, { setPropertyFiltersField })(SearchBox)
