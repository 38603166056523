import React, { useEffect, useState } from "react"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from "react-responsive-carousel"
import { connect } from "react-redux"
import ReactPlayer from "react-player/file"
import { getHomeSliderAction } from "../actions/dataActions"
import cover from "../assets/images/cover.png"
import header from "../assets/images/header.png"
import ChevLeft from "../assets/icons/chevLeft"
import ChevRight from "../assets/icons/chevRight"

const defaultDuration = 4000

const fallbackSlider = [{ url: cover }, { url: header }]

const HomeSliderItem = ({ url, type, active, onStartVideo, onEndVideo }) => {
  if (type === "video") {
    return (
      <ReactPlayer
        className="react-player h-92vh"
        width="100%"
        controls
        playing={active}
        url={url}
        muted
        onPlay={onStartVideo}
        onStart={onStartVideo}
        onEnded={onEndVideo}
        onError={onEndVideo}
      />
    )
  }

  return (
    <img
      alt={`home-slider`}
      src={url}
      className="br8 w-100 home-slider-image h-92vh"
    />
  )
}

const HomeSlider = ({ homeSlider, getHomeSliderAction, ...props }) => {
  const [autoPlay, setAutoPlay] = useState(true)
  const [activeIndex, setActiveIndex] = useState(0)

  useEffect(() => {
    getHomeSliderAction()
  }, [getHomeSliderAction])

  const sliderAssets =
    homeSlider?.length > 0 ? [...homeSlider] : [...fallbackSlider]

  const items = sliderAssets?.map((item, i) => (
    <HomeSliderItem
      key={item.id || item.url}
      {...item}
      active={activeIndex === i}
      onStartVideo={() => setAutoPlay(false)}
      onEndVideo={() => {
        setAutoPlay(true)
      }}
    />
  ))

  return (
    <div className="px-lg-6 px-md-5 px-3 br16">
      <Carousel
        className="home-slider"
        autoPlay={autoPlay}
        infiniteLoop
        interval={defaultDuration}
        showStatus={false}
        showIndicators={false}
        showThumbs={false}
        onChange={i => setActiveIndex(i)}
        renderArrowPrev={onClick =>
          items?.length > 1 ? (
            <button
              className="home-slider__prev-btn"
              onClick={onClick}
              style={{ top: "48%" }}
            >
              <ChevLeft />
            </button>
          ) : null
        }
        renderArrowNext={onClick =>
          items?.length > 1 ? (
            <button
              className="home-slider__next-btn"
              onClick={onClick}
              style={{ top: "36%" }}
            >
              <ChevRight />
            </button>
          ) : null
        }
      >
        {items}
      </Carousel>
    </div>
  )
}

const mapStateToProps = ({ data: { homeSlider } }) => ({ homeSlider })

export default connect(mapStateToProps, { getHomeSliderAction })(HomeSlider)
