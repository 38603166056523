import React, { Fragment, useEffect, useState } from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import ArrowRight from "../assets/icons/arrowRight"
import AliceCarousel from "react-alice-carousel"
import {
  propertiesImages,
  propertiesCards,
  associationsImages,
  servicesCards,
} from "../constants/headerItems"
import isEmpty from "lodash/isEmpty"
import google from "../assets/images/GooglePlayEn.png"
import apple from "../assets/images/AppStoreEn.png"
import appSection from "../assets/images/section2.png"
import { getProperties } from "../actions/propertiesAction"
import { connect } from "react-redux"
import { navigate } from "gatsby-link"
import { FormattedMessage } from "react-intl"
import ServiceComponent from "../components/serviceComponent"
import HomeSlider from "../components/homeSlider"
import SearchBox from "../components/searchBox"
import Loader from "../components/loader"
import ViewModeButtons from "../components/viewModeButtons"
import PropertyGrid from "../components/propertyGrid"
import { setGeneralModal, setSort } from "../actions/settingsActions"
import modernHome from "../assets/images/modernHomeForSale.svg"
import dataApi from "../_api/dataApi"
import useMediaQuery from "../components/hooksComponents/useMediaQuery"

const IndexPage = props => {
  const { properties, loading, getProperties, setSort } = props
  const [items, setItems] = useState([])
  const [headerItems, setHeaderItems] = useState([])
  const [services, setServices] = useState([])
  const [serviceCategories, setServiceCategories] = useState([])
  const isMobile = useMediaQuery(`(max-width: 768px)`)
  const responsive = {
    0: {
      items: 1,
    },
    600: {
      items: 2,
    },
    1024: {
      items: 4,
    },
  }
  const renderCarouselComponent = ({ title }) => {
    return (
      <Fragment>
        <div className="px-6rem  py-5 py-sm">
          <div className="signInBorder d-flex align-items-center justify-content-between">
            <span className="font-semi-bold black mx-3 sectionName2">{title}</span>
            <button
              className="primary font-bold button-shadow px-3 py-2 button-secondaryBorder viewAllOffersBtn"
              onClick={() => navigate(`/offplan-projects`)}
            >
              <span className="me-3 py9 viewAllOffersText">
                <FormattedMessage defaultMessage="View all offers" />
              </span>
              <ArrowRight size="1.5rem" />
            </button>
          </div>
        </div>
        <div className="custom-carousel ps-lg-5 ps-3">
          <AliceCarousel
            items={items}
            mouseTracking
            disableDotsControls={true}
            disableButtonsControls={true}
            touchMoveDefaultEvents={false}
            responsive={responsive}
            paddingLeft={typeof window != "undefined" && window.innerWidth < 500 ? 10 : 25}
            paddingRight={typeof window != "undefined" && window.innerWidth < 500 ? 10 : 25}
          />
        </div>
      </Fragment>
    )
  }

  const carouselItem = (items, entity) => {
    return setItems(
      items.map((item, index) => (
        <div className="relative action-hover">
          <img
            style={{ width: "100%", height: "100%" }}
            alt={`alt-${index}`}
            src={item.image}
            key={index}
            className="br16"
          />
          <div
            className="absolute bottom-0 border-br8 border-bl8 bg-opacity d-flex justify-content-start align-items-center w-100"
            style={{
              borderBottomRightRadius: "16px",
              borderBottomLeftRadius: "16px",
            }}
          >
            <div className="signInBorder white m-4 py-3 h-100">
              <h1 className="mx-4 px20 font-bold">{item.title}</h1>
              <div className="mx-4 px16">{item.description}</div>
              <div
                className="d-flex justify-content-end align-items-end pointer"
                onClick={() => navigate(`/app/${entity}/${item.id}`)}
              >
                <button className="secondaryBg br8 p-2 my-2 white extra-info">
                  <FormattedMessage defaultMessage="More details" />
                </button>
              </div>
            </div>
          </div>
        </div>
      ))
    )
  }

  useEffect(() => {
    if (!isEmpty(propertiesImages)) {
      carouselItem(propertiesImages, "project")
    }
  }, [propertiesImages])

  useEffect(() => {
    if (!isEmpty(associationsImages)) {
      carouselItem(associationsImages, "association")
    }
  }, [associationsImages])

  useEffect(async () => {
    getProperties({ sort: "createdAt,DESC" })
    await dataApi.getServiceCategory().then(async res => {
      setServiceCategories(res.data)
      await dataApi.getExperts().then(expert => {
        setServices(expert.data)
      })
    })
  }, [])

  return (
    <Layout>
      <Seo title="Home" />
      {loading && <Loader />}
      <div className="home">
        <div className="w-100 relative h-92vh mt-5">
          <HomeSlider />

          <div className="w-100 absolute pb-5 top-0 h-100 d-flex justify-content-center align-items-end">
            <SearchBox />
          </div>
        </div>

        <div className="divider" />

        <div
          className="px-6rem mobileAppContainer"
        >
          <div className="sectionBg br32 shadow">
            <div className="row align-items-center">
              <div className="col-lg-6 col-12 d-lg-flex d-none">
                <img
                  alt="apps"
                  src={appSection}
                  width="100%"
                  height="100%"
                  className="app-section"
                />
              </div>
              <div className="col-lg-6 col-12 white p-5">
                <div
                  style={{
                    fontSize: "20px",
                    weight: "400",
                    lineHeight: "32px",
                  }}
                >
                  <FormattedMessage defaultMessage="IOS & Android Versions" />
                </div>
                <div
                  className="px36-responsive font-semi-bold"
                  style={{ weight: "600", lineHeight: "54px" }}
                >
                  <FormattedMessage defaultMessage="Download our free mobile app" />
                </div>
                <div className="px36-responsive font-semi-bold">
                  <FormattedMessage defaultMessage=" Get Simsar at the palm of your hand" />
                </div>
                <div className="d-flex mt-lg-5 mt-3">
                  <a href="https://www.google.com" target="_blank" className="mx-2 mx-lg-0">
                    <img alt="google" src={google} className="w-100" />
                  </a>
                  <a
                    href="https://www.google.com"
                    target="_blank"
                    className="mx-2 mx-lg-5"
                  >
                    <img alt="apple" src={apple} className="w-100" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="divider" />

        {renderCarouselComponent({ title: "Our Off Plan Projects" })}
        {renderCarouselComponent({ title: "Our Housing Association" })}

        <div className="divider" />

        <div className="greyXLightBg">
          <div
            className="centralPadding"
          >
            <div className="py-5 px-3 px-md-0">
              <div className="signInBorder d-flex align-items-center justify-content-between">
                <span className="font-semi-bold black px36 mx-3">
                  <FormattedMessage defaultMessage="Newly added properties" />
                </span>
                <ViewModeButtons />
              </div>
            </div>
            <div className="row pb-5 px-3 px-md-0">
              <PropertyGrid forceVertical={isMobile} properties={properties?.data?.slice(0, 6)} />
            </div>
            <div className="d-flex align-items-center justify-content-center">
              <button
                onClick={() => {
                  setSort("createdAt,DESC")
                  navigate("/properties")
                }}
                className="primary font-bold button-shadow px-3 br8 py-2 button-secondaryBorder"
              >
                <span className="me-3 py9 viewAllOffersText">
                  <FormattedMessage defaultMessage="View all offers" />
                </span>
                <ArrowRight size="1.5rem" />
              </button>
            </div>
          </div>
        </div>

        <div
        className="py-150px-responsive"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#F8F8F8",
            borderRadius: "16px",
          }}
        >
          <img
            alt="modern home"
            src={modernHome}
            width="83%"
            height="80%"
            class="br16 shadow-lg "
          />
        </div>

        <div className="greyXLightBg">
          <div
            className="centralPadding"
          >
            <div className="py-5 px-3 px-md-0">
              <div className="signInBorder d-flex align-items-center justify-content-between">
                <span className="font-semi-bold black mx-3 sectionName">
                  <FormattedMessage defaultMessage="From our most active brokers" />
                </span>
                {/* <ViewModeButtons /> */}
              </div>
            </div>
            <div className="row pb-5 px-3 px-md-0">
              <PropertyGrid forceVertical={true} properties={properties?.data} />
            </div>
            <div className="d-flex align-items-center justify-content-center" style={{paddingBottom: '40px'}}>
              <button
                onClick={() => {
                  setSort("createdAt,DESC")
                  navigate("/properties")
                }}
                className="primary font-bold button-shadow px-3 br8 py-2 button-secondaryBorder"
              >
                <span className="me-3 py9 viewAllOffersText">
                  <FormattedMessage defaultMessage="View all offers" />
                </span>
                <ArrowRight size="1.5rem" />
              </button>
            </div>
          </div>
        </div>

        <div className="whiteBg">
          <div
            className="centralPadding"
          >
            <div className="py-5 px-3 px-md-0">
              <div className="signInBorder d-flex align-items-center justify-content-between">
                <span className="font-semi-bold black mx-3 sectionName2">
                  <FormattedMessage defaultMessage="Community experts" />
                </span>
                <button
                  className="primary font-bold button-shadow px-3 py-2 button-secondaryBorder viewAllOffersBtn"
                  onClick={() => navigate(`/app/all-services`)}
                >
                  <span className="me-3 py9 viewAllOffersText">
                    <FormattedMessage defaultMessage="View all services" />
                  </span>
                  <ArrowRight size="1.5rem" />
                </button>
              </div>
            </div>
            <div className="row pb-5 px-3 px-md-0">
              {services.map((service, index) => (
                <ServiceComponent
                  key={index}
                  serviceData={service}
                  category={serviceCategories.find(
                    i => i.id === service.category.id
                  )}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

const mapStateToProps = ({ data: { properties }, shared: { loading } }) => ({
  loading,
  properties,
})
export default connect(mapStateToProps, { getProperties, setSort, setGeneralModal })(IndexPage)
